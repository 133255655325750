<template>
    <div class="app-container">
        <div class="app-top-btn"></div>
        <el-table :data="list.data" border fit highlight-current-row>
            <el-table-column
                align="center"
                label="标题"
                min-width="280"
                prop="title"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="发行者名称"
                min-width="120"
                prop="issuer"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="接收者姓名"
                min-width="120"
                prop="userName"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="消息类型"
                min-width="120"
                prop="status"
            >
                <template slot-scope="scope">{{
                    scope.row.source === 0
                        ? '系统'
                        : scope.row.source === 1
                        ? '工会'
                        : scope.row.source === 2
                        ? '商家'
                        : '个人'
                }}</template>
            </el-table-column>
            <el-table-column
                align="center"
                label="消息状态"
                min-width="120"
                prop="status"
            >
                <template slot-scope="scope">{{
                    scope.row.status ? '已读' : '未读'
                }}</template>
            </el-table-column>
            <el-table-column
                align="center"
                label="创建时间"
                min-width="120"
                prop="createTime"
            >
            </el-table-column>
        </el-table>
        <!--分页-->
        <div class="app-pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :total="listTotal"
                :page-size="listQuery.pageSize"
                :current-page="listQuery.pageNum"
                @current-change="pageNumChange"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userlist: [],
            list: {
                data: [],
                form: {
                    ref: 'listForm',
                    title: '新增',
                    show: false,
                    fields: {},
                    rules: {
                        title: [
                            {
                                required: true,
                                message: '必填',
                                trigger: 'blur',
                            },
                        ],
                    },
                },
            },
            listTotal: 0,
            listQuery: {
                pageNum: 1,
                pageSize: 10,
                keyWords: '',
            },
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 分页处理
        pageNumChange(page) {
            if (page != this.listQuery.pageNum) {
                this.listQuery.pageNum = page
                this.getList()
            }
        },
        // 获取列表
        getList() {
            this.$api.message_sendRecordPage(this.listQuery).then((res) => {
                this.listTotal = res.pageInfo.total
                this.list.data = res.data
            })
        },
    },
}
</script>
